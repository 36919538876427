import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const hostsDashboardConfigs = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/hostsDashboard',
        component: React.lazy(() => import('./HostsDashboard')),
      },
    ],
  },

];
