import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const rolesConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        path: '/roles',
        component: React.lazy(() => import('./Roles')),
      },
    ],
  },

];
