import React from "react";

export const ssoConfigs = [
  {
    //auth: ["admin", "user"],
    routes: [
      {
        path: "/sso",
        component: React.lazy(() => import("./SSO")),
      },
    ],
  },
];
