import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const devicesConfigs = [
  {
    auth: ["admin", "user"],
    routes: [
      {
        path: '/hosts',
        component: React.lazy(() => import('./Hosts')),
      },
    ],
  },

];
