import React, { useState } from "react";
import List from "@material-ui/core/List";
import { Box, TextField } from "@material-ui/core";
import routesConfig from "../../../../modules/routesConfig";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import VerticalNavGroup from "./VerticalNavGroup";
import { useSelector } from "react-redux";
import { onSetSelectedViewDescription } from "../../../../redux/actions";

const Navigation = () => {
  const theApp = useSelector(({ sshepApp }) => sshepApp);
  const [description, setDescription] = useState("Sample Description");
  /*
  let userItem = localStorage.getItem("user");
  if( userItem ){
      let user = JSON.parse(userItem);

    routesConfig.forEach(item => {
      switch( item.id ){
        case "sessions":
              item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-recording");
          break;
        case "hosts":
          item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-host");
          break;
        case "roles":
              item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-user-role");
          break;
        case "groups":
          item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-group");
          break;
        case "users":
          item.hidden = false; //item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-user");
          break;
        default:
          item.hidden = false;
          break;
      }
    });  
  }*/

  return (
    <>
      <List>
        {routesConfig.map(
          (item) =>
            !item.hidden && (
              <React.Fragment key={item.id}>
                {item.type === "group" && (
                  <VerticalNavGroup item={item} level={0} />
                )}

                {item.type === "collapse" && (
                  <VerticalCollapse item={item} level={0} />
                )}

                {item.type === "item" && <VerticalItem item={item} level={0} />}
              </React.Fragment>
            )
        )}
      </List>
      <TextField
        multiline
        disabled
        style={{ padding: "20px", width: "100%" }}
        value={theApp.selectedViewDescription}
      ></TextField>
    </>
  );
};

export default Navigation;
