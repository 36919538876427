import React from "react";
import { authRole } from "../../shared/constants/AppConst";

export const systemStatusConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        path: "/systemStatus",
        component: React.lazy(() => import("./SystemStatus")),
      },
    ],
  },
];
