import React, { useSelector } from "react";
import metadata from "../../metadata.json";
import moment from "moment";
import { ReactComponent as OneShotIcon } from "assets/images/square-1-thin.svg";
import { ReactComponent as TimeLimitedIcon } from "assets/images/clock-two-thirty-thin.svg";

export const renderMetadata = () => {
  let thisYear = moment().year();
  thisYear = thisYear >= 2024 ? thisYear : 2024;
  let copyrightNotice = "© " + thisYear + " FullArmor Corporation";

  return (
    <>
      <div style={{ padding: "10px 20px 0px 20px", width: "100%" }}>
        {"SSHepherd® Server " +
          (metadata
            ? `${metadata?.version} (${metadata?.buildNumber}) ${metadata?.buildTag}`
            : "")}
      </div>
      <div style={{ padding: "0px 20px 10px 20px", width: "100%" }}>
        {copyrightNotice}
      </div>
    </>
  );
};

export const renderSecurityConfiguration = (securityConfiguration) => {
  return (
    <>
      <div
        style={{
          marginLeft: 20,
          marginTop: 10,
          display: "flex",
          color: "#c0c0c8",
        }}
      >
        {securityConfiguration?.api_limit_single_use && (
          <div style={{ marginRight: 5 }}>
            <OneShotIcon
              style={{
                width: 20,
                height: 20,
                stroke: "#909098",
                strokeWidth: 2,
                fill: "#909098",
              }}
              title="Security configuration has per-request authentication enabled"
            />
          </div>
        )}
        {securityConfiguration?.api_limit_freshness && (
          <div style={{ marginRight: 5 }}>
            <TimeLimitedIcon
              style={{
                width: 20,
                height: 20,
                stroke: "#909098",
                strokeWidth: 2,
                fill: "#909098",
              }}
              title="Security configuration has time-limited authentication enabled"
            />
          </div>
        )}
      </div>
    </>
  );
};
