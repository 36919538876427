import React from "react";
import { authRole } from "../../shared/constants/AppConst";

export const settingsConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        path: "/settings",
        component: React.lazy(() => import("./Settings")),
      },
    ],
  },
];
