import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const recordingsConfigs = [
  {
    auth: ["admin"],
    routes: [
      {
        auth: ["admin"],
        path: '/recordings',
        component: React.lazy(() => import('./Sessions')),
      },
      {
          auth: ["admin"],
        path: '/recordings/sessions',
        component: React.lazy(() => import('./Sessions')),
      },
      {
          auth: ["admin"],
        path: '/sessions',
        component: React.lazy(() => import('./Sessions')),
      },

    ],
  },

];
