import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
//import MailApp from './MailApp';
import Common from './Common';
//import Editors from './Editors';
import SSHepApp from './SSHepApp';
//import Dashboard from './Dashboard';
//import Gallery from './Gallery';
//import UserList from './UserList';
//import Ecommerce from './Ecommerce';
//import ContactApp from './ContactApp';
import Auth from './Auth';
//import ChatApp from './ChatApp';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    //mailApp: MailApp,
    //dashboard: Dashboard,
    common: Common,
    //editors: Editors,
    sshepApp: SSHepApp,
    //gallery: Gallery,
    //userList: UserList,
    //ecommerce: Ecommerce,
    //contactApp: ContactApp,
    //chatApp: ChatApp,
  });
export default reducers;
