/*
import './mail';
import './dashboard/index';
import './editors/index';
import './gallery/index';
import './todo/index';
import './userList';
import './ecommerce/ecommerce';
import './contact/index';
import './chat/index';
*/