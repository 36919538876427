import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import routesConfig from '../../../../modules/routesConfig';
import Box from '@material-ui/core/Box';

const HorizontalNav = () => {

  let userItem = localStorage.getItem("user");
  if( userItem ){
    let user = JSON.parse(userItem);
    routesConfig.forEach(item => {
      switch( item.id ){
        case "sessions":
          item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-recording");
          break;
        case "hosts":
          item.hidden = !user  || !user.effectivePermissions || !user.effectivePermissions.includes("list-host");
          break;
        case "roles":
          item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-user-role");
          break;
        case "groups":
          item.hidden = !user || !user.effectivePermissions || !user.effectivePermissions.includes("list-group");
          break;
        case "users":
          item.hidden = false;
          break;
        default:
          item.hidden = false;
      }
    });  
  }

  return (
    <List className='navbarNav'>
      {routesConfig.map((item) => !item.hidden && (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <HorizontalGroup item={item} nestedLevel={0} />
          )}

          {item.type === 'collapse' && (
            <HorizontalCollapse item={item} nestedLevel={0} />
          )}

          {item.type === 'item' && (
            <HorizontalItem item={item} nestedLevel={0} />
          )}

          {item.type === 'divider' && (
            <Box my={5} clone>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;
