import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTH_TOKEN,
  SHOW_MESSAGE,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  RESET_SSHEPHERD_STATE,
  GET_SSHEPHERD_AUTH_PROVIDER,
} from "../../shared/constants/ActionTypes";

import jwtAxios from "../../@crema/services/auth/jwt-auth/jwt-api";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import { AuthType } from "../../shared/constants/AppEnums";
import { authProviders, defaultUser } from "../../shared/constants/AppConst";
import { authRole } from "../../shared/constants/AppConst";
import { fetchInitialData } from "../actions/SSHepApp";

export const onJwtUserSignUp = ({ email, password, name }) => {
  console.log("email, password", { email, password, name });
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, name, password };
    try {
      const res = await jwtAxios.post("users", body);
      localStorage.setItem("token", res.data.token);
      dispatch(setJWTToken(res.data.token));

      //dispatch(loadJWTUser());
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: AuthType.JWT_AUTH,
          //displayName: res.data.name,
          email: email,
          role: defaultUser.role,
          token: res.data.response.user.authentication_token,
          //photoURL: res.data.avatar,
        },
      });
    } catch (err) {
      console.log("error", err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = ({
  email,
  password,
  authType,
  callback,
  isOneShot,
}) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = {
      email,
      password,
      headers: {
        Cookie: "session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;", // Clear the cookies for the login call
      },
    };

    try {
      if (isOneShot) {
        localStorage.removeItem("oneshotToken");
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }

      // authType = authProviders.LDAP; // test forcing provider to LDAP...

      //console.log("onJwtSignIn - Clearing Cookie");
      document.cookie =
        "session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // Clear Cookie
      delete jwtAxios.defaults.headers.common["x-auth-token"];
      let loginURL = "/api/login?include_auth_token";
      if (authType == authProviders.LDAP)
        loginURL = "/ldap/login?include_auth_token";
      const res = await jwtAxios.post(loginURL, body);

      if (isOneShot) {
        localStorage.setItem(
          "oneshotToken",
          res.data.response.user.authentication_token
        );
      } else {
        localStorage.setItem(
          "token",
          res.data.response.user.authentication_token
        );
        dispatch(setJWTToken(res.data.response.user.authentication_token));

        //dispatch(loadJWTUser());
        let user = res.data.response.user;
        user.authType = AuthType.JWT_AUTH;
        user.isAdmin = user.roles.filter((r) => r.name == "admin").length > 0;
        user.role = user.isAdmin == true ? authRole.admin : authRole.user; // defaultUser.role;
        let effectivePermissions = [];
        user.roles.forEach((r) => {
          r.permissions.forEach((p) => {
            if (!effectivePermissions.includes(p)) effectivePermissions.push(p);
          });
        });
        user.effectivePermissions = effectivePermissions;

        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: UPDATE_AUTH_USER,

          payload: {
            authType: AuthType.JWT_AUTH,
            //displayName: res.data.name,
            user: res.data.response.user,
            email: email,
            role: user.role,
            token: res.data.response.user.authentication_token,
            //photoURL: res.data.avatar,
          },
          //payload: user,
        });
        dispatch(fetchInitialData());
      }

      if (callback) {
        callback(res);
      }
    } catch (err) {
      //console.log('error!!!!', err.response.data.error);

      if (
        err.response &&
        err.response.data &&
        err.response.data.response &&
        err.response.data.response.errors
      ) {
        let emailError = err.response.data.response.errors.find((e) =>
          e.includes("email")
        );
        let passwordError = err.response.data.response.errors.find((e) =>
          e.includes("password")
        );

        if (emailError) dispatch(showMessage(emailError));
        else if (passwordError) dispatch(showMessage(passwordError));
        else dispatch(fetchError(err.message));
      } else {
        dispatch(fetchError(err.message));
      }

      if (callback) {
        callback(err?.response);
      }
    }
  };
};

{
  /*
export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get('/auth');
      dispatch(fetchSuccess());
      
      console.log('res.data', res.data);
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {          
          authType: AuthType.JWT_AUTH,
          displayName: res.data.name,
          email: res.data.email,
          role: defaultUser.role,
          token: res.data._id,
          photoURL: res.data.avatar,
        },
      });
      
    } catch (err) {
      console.log('error!!!!', err.response.error);
      dispatch(fetchError(err.response.error));
    }
  };
};
*/
}

export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      //const res = await jwtAxios.get('/auth');
      dispatch(fetchSuccess());

      //console.log('res.data', res.data);
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: localStorage.getItem("user"),
      });
    } catch (err) {
      console.log("error", err.response.error);
      dispatch(fetchError(err.response.error));
    }
  };
};

export const onResetJwtPassword = (email, history) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    let res = await jwtAxios.post("/api/logout");

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    //console.log("onResetJwtPassword - Clearing Cookie");
    document.cookie =
      "session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // Clear Cookie

    jwtAxios
      .post("/api/user/forgot-password", {
        email: email,
      })
      .then((data) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHOW_MESSAGE,
            payload: `Check your email at ${email} for information about how to reset your password.`,
          });
          history.push("/forget-password", { email: email });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onResetJwtOtherUserPassword = (email, history) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    jwtAxios
      .post("/api/user/forgot-password", {
        email: email,
      })
      .then((data) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          alert("Password reset email sent.");

          dispatch({
            type: SHOW_MESSAGE,
            payload: `Password reset email sent to ${email}.`,
          });
          //history.push('/users', { email: email });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({ type: SET_AUTH_TOKEN, payload: token });
  };
};

export const onJWTAuthSignout = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    let res = await jwtAxios.post("/api/logout");

    setTimeout(() => {
      dispatch({ type: RESET_SSHEPHERD_STATE });
      dispatch({ type: SIGNOUT_AUTH_SUCCESS });
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: GET_SSHEPHERD_AUTH_PROVIDER, payload: -1 }); // reset

      localStorage.removeItem("token");
      localStorage.removeItem("user");

      //console.log("onJwtAuthSignout - Clearing Cookie");
      //document.cookie = "session=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // Clear Cookie
      document.cookie = ""; // Clear Cookie
      //window.location = "/";
      window.location = "/#/signout";
    }, 500);
  };
};

export const onGetSSOToken = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    let res = await jwtAxios
      .get("/sso/token")
      .then((result) => {
        console.log("onGetSSOToken - result", result);
        if (
          result &&
          result.data &&
          result.data.user &&
          result.data.user.authentication_token
        ) {
          dispatch({ type: FETCH_SUCCESS });
          console.log(
            "onGetSSOToken - authentication_token: ",
            result?.data?.user?.authentication_token
          );

          localStorage.removeItem("token");
          localStorage.removeItem("user");

          let authToken = result.data.user.authentication_token;
          localStorage.setItem("token", authToken);

          let user = result.data.user;
          user.authType = AuthType.JWT_AUTH;
          user.isAdmin = user.roles.filter((r) => r.name == "admin").length > 0;
          user.role = user.isAdmin == true ? authRole.admin : authRole.user; // defaultUser.role;
          let effectivePermissions = [];
          user.roles.forEach((r) => {
            r.permissions.forEach((p) => {
              if (!effectivePermissions.includes(p))
                effectivePermissions.push(p);
            });
          });
          user.effectivePermissions = effectivePermissions;
          localStorage.setItem("user", JSON.stringify(user));

          dispatch({
            type: UPDATE_AUTH_USER,

            payload: {
              authType: AuthType.JWT_AUTH,
              //displayName: res.data.name,
              user: result.data.user,
              email: result.data.user.email,
              role: user.role,
              token: result.data.user.authentication_token,
              //photoURL: res.data.avatar,
            },
            //payload: user,
          });

          if (window.opener) {
            // <-- This is a popup window
            console.log(
              "JWTAuth Sending newAuthToken message to main window...",
              authToken
            );
            window.opener.postMessage(
              { type: "newAuthToken", data: authToken },
              "*"
            );
            console.log("ToDo - onGetSSOToken() - Close Popup Window Here");
            //window.close();
            return;

            //console.log("closing oneshotAuthWindow");
            //theApp.oneshotAuthWindow.close();
          } else {
            console.log(
              "JWTAuth window.opener is null, authToken is",
              authToken
            );
            console.log("updating state with new token", authToken);
            dispatch(setJWTToken(authToken));
            dispatch(fetchInitialData());
          }

          /*
          console.log("theApp.oneshotAuthWindow", theApp.oneshotAuthWindow);
          if( !theApp.oneshotAuthWindow){
            console.log("this auth was not for a one-shot window, so navigate to /");
            window.location = "/";
          }*/
        } else {
          console.log("Boo");
          dispatch({ type: FETCH_ERROR, payload: result.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
