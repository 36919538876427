import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchInitialData } from "../../redux/actions";
import {
  showMessage,
  showWarning,
  showError,
} from "../../redux/actions/Common";

const Initialization = () => {
  const dispatch = useDispatch();
  const theApp = useSelector(({ sshepApp }) => sshepApp);

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchInitialData());
    }
  }, []);

  useEffect(() => {
    if (theApp?.licenseConfig?.daysUntilExpired != null) {
      //console.log("theApp?.licenseConfig?.daysUntilExpired", theApp?.licenseConfig?.daysUntilExpired);

      setTimeout(() => {
        if (theApp?.licenseConfig?.daysUntilExpired < 0) {
          dispatch(showError("License expired"));
        } else if (
          theApp?.licenseConfig?.daysUntilExpired >= 0 &&
          theApp?.licenseConfig?.daysUntilExpired <= 3
        ) {
          dispatch(
            showError(
              "License expires in " +
                theApp.licenseConfig.daysUntilExpired +
                " days"
            )
          ); //messages["group.created"],
        } else if (
          theApp?.licenseConfig?.daysUntilExpired > 3 &&
          theApp?.licenseConfig?.daysUntilExpired <= 30
        ) {
          dispatch(
            showWarning(
              "License expires in " +
                theApp.licenseConfig.daysUntilExpired +
                " days"
            )
          ); //messages["group.created"],
        }
      }, 5000);
    }
  }, [theApp?.licenseConfig]);

  return null;
};

export default Initialization;
